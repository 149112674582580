/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #030a1a;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: #030a1a;
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.MhjEzw4C {
  position: fixed;
  right: 0;
  bottom: 80px;
  z-index: 600;
}
.MhjEzw4C .__0k2WFzR2 {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
}
.MhjEzw4C .__36eKV7LN {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
}
.MhjEzw4C .__0sBiLXPC {
  width: 48px;
  height: 120px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 4px 0 0 4px;
  box-shadow: 4px 4px 8px 0 rgba(3, 10, 26, 0.1), -4px -4px 8px 0 rgba(255, 255, 255, 0.08), 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14), inset 0 0 3px 0 rgba(255, 255, 255, 0.5);
}
.MhjEzw4C .__6kvHjYWy {
  width: 48px;
  height: 120px;
  overflow: hidden;
  background: linear-gradient(315deg, rgba(255, 255, 255, 0), #bbd4fe);
  border-radius: 4px 0 0 4px;
}
.MhjEzw4C .__6kvHjYWy .ZaKrbGJG {
  width: 47px;
  height: 118px;
  margin: 1px;
  padding: 16px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 4px 0 0 4px;
}
.MhjEzw4C .__6kvHjYWy .ZaKrbGJG p {
  width: 16px;
  height: 88px;
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-shadow: 4px 4px 8px rgba(3, 10, 26, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.08);
}
.MhjEzw4C .G2wGDqWT {
  position: absolute;
  top: 50px;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
}
.MhjEzw4C .VWRVFhu4 {
  position: absolute;
  top: -100px;
  right: 0;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.MhjEzw4C .VWRVFhu4 .__9t8n3CZH {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 1;
  display: block;
  width: 10px;
  height: 10px;
}
.MhjEzw4C .CL7Rr_PD {
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
}
.MhjEzw4C .CL7Rr_PD > *:last-child {
  margin-bottom: 0 !important;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
  position: relative;
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
  box-shadow: none;
  cursor: pointer;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .jQiK_Ahf {
  margin: 6px auto 0;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .UT2tLGEb {
  position: absolute;
  right: -1px;
  bottom: -1px;
  left: -1px;
  height: 15px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 0 0 0 4px;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .UT2tLGEb span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.83);
  color: #fff;
  line-height: 15px;
}
.MhjEzw4C .CL7Rr_PD .p5oECPtB .UT2tLGEb span.uVqrv7Ie {
  padding-left: 2px;
  font-weight: normal;
  transform: scale3d(0.75, 0.83, 1);
  transform-origin: left center;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
  position: relative;
  width: 48px;
  height: 48px;
  box-shadow: none;
  cursor: pointer;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw .jQiK_Ahf {
  margin: 6px auto 0;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw:hover .jQiK_Ahf {
  animation: __3MTsqLTz 1.6s;
}
@keyframes __3MTsqLTz {
  0% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(0);
  }
  25% {
    transform: translate3d(10px, -50px, 0) scale(0.5) rotateZ(0);
  }
  47% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(180deg);
  }
  50% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(180deg);
  }
  65% {
    transform: translate3d(-20px, 30px, 0) scale(1.2) rotateZ(180deg);
  }
  90% {
    transform: translate3d(-20px, 30px, 0) scale(1.2) rotateZ(0);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateZ(0);
  }
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw .UT2tLGEb {
  position: absolute;
  right: -1px;
  bottom: -1px;
  left: -1px;
  height: 15px;
  background: linear-gradient(360deg, #1a66ff 0%, #389fff 100%);
  border-radius: 0 0 0 4px;
}
.MhjEzw4C .CL7Rr_PD .FxOTCSQw .UT2tLGEb span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.83);
  color: #fff;
  line-height: 15px;
}
.MhjEzw4C ._lGN4Def {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  cursor: pointer;
}
.MhjEzw4C ._lGN4Def .Kpu30OYl {
  position: absolute;
  top: 0;
  right: -1px;
  display: flex;
  align-items: center;
  width: 0;
  height: 48px;
  padding: 12px 0;
  overflow: hidden;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  background-color: #1A66FF;
  border-radius: 4px 0 0 4px;
  box-shadow: 4px 4px 8px 0 rgba(3, 10, 26, 0.1), -4px -4px 8px 0 rgba(255, 255, 255, 0.08);
  transition: width 0.4s;
}
.MhjEzw4C ._lGN4Def .Kpu30OYl .usvBZZFS {
  padding-right: 20px;
  padding-left: 8px;
}
.MhjEzw4C ._lGN4Def .Kpu30OYl .anticon {
  margin-left: 12px;
  font-size: 24px;
}
.MhjEzw4C ._lGN4Def:first-child .Kpu30OYl {
  top: -1px;
}
.MhjEzw4C ._lGN4Def:hover .Kpu30OYl {
  width: 120px;
}
.MhjEzw4C ._lGN4Def.__2bGlnay8:hover .Kpu30OYl {
  width: 164px;
}
.MhjEzw4C ._lGN4Def:not(:last-child)::before {
  position: absolute;
  right: 12px;
  bottom: 0;
  left: 12px;
  height: 1px;
  background-color: #DCDFE5;
  content: '';
}
.MhjEzw4C .KoYQNw9i {
  position: relative;
}
.MhjEzw4C .KoYQNw9i::after {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: FEKM_2vQ 1.5s infinite;
  content: '';
}
@keyframes FEKM_2vQ {
  0% {
    box-shadow: 0 0 0 0 rgba(229, 82, 69, 0.7);
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 8px rgba(229, 82, 69, 0.7);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.MhjEzw4C .__2bGlnay8 {
  cursor: default;
}
.MhjEzw4C .__2bGlnay8 .usvBZZFS {
  font-size: 22px;
  font-family: 'CondensedBold';
}
.MhjEzw4C .__2bGlnay8 .usvBZZFS .l0g7OW2h {
  opacity: 0.5;
}
.MhjEzw4C .__4FwhYNFR {
  background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.MhjEzw4C .__4FwhYNFR .jQiK_Ahf .anticon {
  color: #475266;
}
.MhjEzw4C .__4FwhYNFR:hover .jQiK_Ahf .anticon {
  color: #1A66FF;
}
.jQiK_Ahf {
  position: relative;
  width: 24px;
  height: 24px;
}
.jQiK_Ahf .anticon {
  color: #1A66FF;
  font-size: 24px;
}
.u6AbdtHj {
  display: block;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  transform: scale(0.83);
}
.ue7KQi7O .ant-popover-inner-content {
  padding: 0;
  background-color: #fff;
  border-radius: 4px;
}
.ue7KQi7O .ant-popover-inner {
  border-radius: 4px;
  box-shadow: none;
}
.ue7KQi7O .ant-popover-content {
  border-radius: 4px;
}
.ue7KQi7O .dMYq7_ft {
  width: 280px;
  padding: 11px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 rgba(3, 10, 26, 0.12);
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl {
  position: relative;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .pP_WVNkA {
  width: 100%;
  height: 60px;
  padding: 10px 0 10px 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .pP_WVNkA .KoYQNw9i {
  width: 40px;
  height: 40px;
  background-image: url('/images/bridge/icon-circle-message.png');
  background-size: 40px 40px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .pP_WVNkA .X62WyE7W {
  position: absolute;
  right: 0;
  bottom: 2px;
  width: 10px;
  height: 10px;
  background: #1a66ff;
  border: 2px solid #fff;
  border-radius: 10px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .ZiW8hFUU {
  width: 100%;
  height: 60px;
  padding: 10px 0 10px 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .ZiW8hFUU .K6aiJ6tZ {
  width: 40px;
  height: 40px;
  background-image: url('/images/bridge/icon-phone-default.svg');
  background-size: 40px 40px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .b3gaje5O {
  width: 100%;
  height: 72px;
  padding: 10px 0 10px 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .b3gaje5O .BsvzQLgT {
  width: 40px;
  height: 40px;
  background-image: url('/images/bridge/icon-message-default.svg');
  background-size: 40px 40px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .jQiK_Ahf {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background: #f2f5fa;
  border-radius: 50%;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .jQiK_Ahf .anticon {
  color: #1A66FF;
  font-size: 24px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj {
  position: absolute;
  display: inline-block;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj .__1D4F96DO {
  display: block;
  width: 84px;
  height: 20px;
  color: #030a1a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj .usvBZZFS {
  display: flex;
  width: 100%;
  height: 17px;
  color: #858c99;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.ue7KQi7O .dMYq7_ft .Kpu30OYl .Hz6QAvKj .AX9J3fIc {
  width: 11px;
  white-space: nowrap;
}
.ue7KQi7O .dMYq7_ft .pP_WVNkA:hover {
  background: linear-gradient(270deg, #fff 0%, #e9f0ff 100%);
  cursor: pointer;
}
.ue7KQi7O .dMYq7_ft .pP_WVNkA:hover .KoYQNw9i {
  background-image: url('/images/bridge/icon-circle-message-hover.png');
  transition-duration: 0.5s;
}
.ue7KQi7O .dMYq7_ft .pP_WVNkA:hover .usvBZZFS {
  color: #1a66ff;
}
.ue7KQi7O .dMYq7_ft .ZiW8hFUU:hover {
  background: linear-gradient(270deg, #fff 0%, #e9f0ff 100%);
  cursor: pointer;
}
.ue7KQi7O .dMYq7_ft .ZiW8hFUU:hover .K6aiJ6tZ {
  background-image: url('/images/bridge/icon-phone-hover.svg');
  transition-duration: 0.5s;
}
.ue7KQi7O .dMYq7_ft .ZiW8hFUU:hover .usvBZZFS {
  color: #1a66ff;
}
.ue7KQi7O .dMYq7_ft .b3gaje5O:hover {
  background: linear-gradient(270deg, #fff 0%, #e9f0ff 100%);
  cursor: pointer;
}
.ue7KQi7O .dMYq7_ft .b3gaje5O:hover .BsvzQLgT {
  background-image: url('/images/bridge/icon-message-hover.svg');
  transition-duration: 0.5s;
}
.ue7KQi7O .dMYq7_ft .b3gaje5O:hover .usvBZZFS {
  color: #1a66ff;
}
.lK3KipT1 .ant-popover-inner {
  border-radius: 4px;
  box-shadow: 0 10px 32px 0 rgba(3, 10, 26, 0.16);
}
.lK3KipT1 .ant-popover-inner-content {
  padding: 0;
}
.lK3KipT1 .FNrewtUl {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  padding: 40px 20px;
  white-space: nowrap;
  text-align: center;
}
.lK3KipT1 .FNrewtUl .__03h_72N6 {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
  color: #858c99;
  cursor: pointer;
  transition: 0.3s;
}
.lK3KipT1 .FNrewtUl .__03h_72N6:hover {
  opacity: 0.8;
}
.lK3KipT1 .FNrewtUl .I6XAUiJa {
  margin-bottom: 10px;
  color: #030a1a;
  font-weight: bold;
  font-size: 16px;
}
.lK3KipT1 .FNrewtUl .q0abEev6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 136px;
  background-color: rgba(242, 245, 250, 0.5);
  border-radius: 8px;
}
.lK3KipT1 .FNrewtUl .q0abEev6 > span {
  box-shadow: 0 0 20px 0 rgba(19, 18, 60, 0.14);
}
.lK3KipT1 .FNrewtUl .__9d_VICg8 {
  width: 112px;
  height: 112px;
  margin: 12px 0 0 12px;
}
.lK3KipT1 .FNrewtUl .mWcvDc_h {
  margin: 10px 0 20px;
  color: #030a1a;
  font-weight: 500;
  font-size: 12px;
}
.lK3KipT1 .FNrewtUl .gpt4rm9E {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 8px;
       column-gap: 8px;
  row-gap: 8px;
}
.lK3KipT1 .FNrewtUl .TUj6tlzp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 40px;
  color: #030a1a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-image: url('/images/qrcode/gift_item_bg.png');
  background-size: 100%;
}
.lK3KipT1 .FNrewtUl .TUj6tlzp div {
  flex: 1 1 auto;
  line-height: 20px;
  text-align: center;
  background: #e9f0ff;
  border-radius: 3px;
}
.lK3KipT1 .FNrewtUl .TUj6tlzp div span {
  transform: scale(0.93);
}
.lK3KipT1 .FNrewtUl .TUj6tlzp div:last-child {
  margin-left: 12px;
}
.ivGjEeBV {
  position: absolute;
  top: 80px;
  right: 100%;
  width: 246px;
  height: 130px;
}
.ivGjEeBV .__5lyeZweA {
  position: absolute;
  bottom: 24px;
  left: 17px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  text-shadow: 0 0 12px rgba(26, 102, 255, 0.5);
}

.PortraitsEntry_entry__XMO49{margin-bottom:12px;display:flex;align-items:center;justify-content:center;width:48px;height:48px;color:#fff;font-weight:600;font-size:16px;line-height:18px;text-align:center;background:linear-gradient(0deg, #1a66ff 0%, #389fff 100%);border:1px solid #1a66ff;border-radius:4px 0 0 4px;box-shadow:none;cursor:pointer}.PortraitsEntry_entryPopover__Hi9xr .ant-popover-inner{border-radius:4px;box-shadow:0 10px 32px 0 rgba(3,10,26,.16)}.PortraitsEntry_popoverContainer__ZQbuh{position:relative;padding-top:4px;cursor:pointer}.PortraitsEntry_popoverContainer__ZQbuh .PortraitsEntry_closeIcon__4aLC8{position:absolute;top:-4px;right:-8px;color:#999;cursor:pointer}.PortraitsEntry_title__rCo0o{font-weight:400;font-size:16px;text-align:center}.PortraitsEntry_titleStrong__4CAGt{margin:8px 4px 0;padding:4px 8px;color:#fff;font-weight:500;font-size:16px;background-color:#1a66ff;border-radius:4px}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sJxKNwbt {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10003;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 10, 26, 0.6);
}
.sJxKNwbt .VmqtaOY_ {
  position: relative;
  width: 506px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 20px 40px 0 rgba(3, 10, 26, 0.2);
}
.sJxKNwbt .VmqtaOY_ .o14QLmf4 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}
.sJxKNwbt .VmqtaOY_ .o14QLmf4 .warn {
  height: 24px;
  margin-right: 8px;
}
.sJxKNwbt .VmqtaOY_ .o14QLmf4 .title {
  color: #030a1a;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  white-space: nowrap;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 86px;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz .xVsBYmiW {
  position: relative;
  width: 44px;
  height: 46px;
  margin-bottom: 16px;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz .Ul1HilZE {
  position: relative;
  color: #030a1a;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}
.sJxKNwbt .VmqtaOY_ .xE7YuNCg .dkHMkggz .Q598zSNB {
  position: relative;
  color: #ef6463;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
}
.sJxKNwbt .VmqtaOY_ .B15UXsjs {
  margin-top: 20px;
  text-align: right;
}
.sJxKNwbt .VmqtaOY_ .B15UXsjs button {
  padding: 4px 12px;
}

.TopBanner_container__qjY2A{width:100%;min-width:1180px;height:40px}.TopBanner_container__qjY2A .TopBanner_banner___xbCR{position:fixed;z-index:100;display:flex;align-items:center;justify-content:center;width:100%;min-width:1180px;height:40px;background-image:url("/images/banner/top_banner_bg.png");background-size:100% 100%;cursor:pointer}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_label1__LFagd{color:#fff;font-weight:bold;font-size:24px;line-height:36px;background:linear-gradient(to right, #ffefd4 0%, #ffd7a2 100%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_label2__jZRoS{min-width:400px;color:#fff;font-weight:500;font-size:20px;line-height:32px;white-space:nowrap;text-align:center;text-shadow:0 2px 4px #0154d5}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_btn__uuoT2{display:flex;align-items:center;justify-content:center;width:118px;height:32px;margin-left:12px;background:linear-gradient(205deg, #fff2d6 0%, #fff2eb 100%);border:2px solid #fff2eb;border-radius:4px;cursor:pointer}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_btn__uuoT2 .TopBanner_btnText__56iBw{color:#e66d16;font-weight:600;font-size:15px;line-height:16px}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_btn__uuoT2 .TopBanner_arrow__vslvX{margin-left:4px}.TopBanner_container__qjY2A .TopBanner_banner___xbCR .TopBanner_closeIconWrapper__ONPrA{position:absolute;right:18px;display:flex;align-items:center;justify-content:center;width:22px;height:22px;background:rgba(255,255,255,.2);border-radius:20px;cursor:pointer}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.y1fU5arT {
  padding-top: 58px;
}

